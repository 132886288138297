<template>
  <v-container id="crud" fluid tag="section">
    <v-card>
      <!--
            <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="'Busqueda de ' + title"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            -->
      <v-data-table
        :headers="headers"
        :items="filteredDesserts"
        :sort-by="title"
        class="elevation-1"
      >
        <template v-slot:[`header.monto`]="{ header }">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                :color="dessertMonto ? 'primary' : ''"
              >
                mdi-filter
              </v-icon>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="dessertMonto"
                class="pa-4"
                type="text"
                label="Ingrese datos para buscar"
                :autofocus="true"
              ></v-text-field>
              <v-btn
                @click="dessertMonto = ''"
                small
                text
                color="primary"
                class="ml-2 mb-2"
                >Borrar</v-btn
              >
            </div>
          </v-menu>
        </template>
        <template v-slot:[`header.fecha`]="{ header }">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <!-- <v-btn icon v-bind="attrs" v-on="on"> -->
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                :color="dessertFecha ? 'primary' : ''"
              >
                mdi-filter
              </v-icon>
              <!-- </v-btn> -->
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="dessertFecha"
                class="pa-4"
                type="text"
                label="Ingrese datos para buscar"
                :autofocus="true"
              ></v-text-field>
              <v-btn
                @click="dessertFecha = ''"
                small
                text
                color="primary"
                class="ml-2 mb-2"
                >Borrar</v-btn
              >
            </div>
          </v-menu>
        </template>
        <template v-slot:[`header.estado`]="{ header }">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <!-- <v-btn icon v-bind="attrs" v-on="on"> -->
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                :color="dessertEstado ? 'primary' : ''"
              >
                mdi-filter
              </v-icon>
              <!-- </v-btn> -->
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="dessertEstado"
                class="pa-4"
                type="text"
                label="Ingrese datos para buscar"
                :autofocus="true"
              ></v-text-field>
              <v-btn
                @click="dessertEstado = ''"
                small
                text
                color="primary"
                class="ml-2 mb-2"
                >Borrar</v-btn
              >
            </div>
          </v-menu>
        </template>

        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <v-dialog v-model="dialog" max-width="50%" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mx-2"
                  fab
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon blue>mdi-plus</v-icon></v-btn
                >
              </template>
              <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn dark text @click="$save()" :disabled="!valid"
                        >Guardar</v-btn
                      >
                    </v-toolbar-items>
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="6" sm="12">
                          <vuetify-money
                            v-model="editedItem.monto"
                            label="Monto"
                            title="Monto"
                            v-bind:options="options"
                            clearable
                            :rules="[$rulesFloatRequerido]"
                          />
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                          <v-menu
                            v-model="menuFecha"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="computedFecha"
                                label="Fecha del Gasto"
                                persistent-hint
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="[$rulesRequerido]"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.fecha"
                              no-title
                              @input="menuFecha = false"
                              :max="nowDate"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6" sm="12">
                          <v-menu
                            v-model="menuFechaVencimiento"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="computedFechaVencimiento"
                                label="Fecha de Vencimiento"
                                persistent-hint
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.fecha_vencimiento"
                              no-title
                              @input="menuFechaVencimiento = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                          <v-menu
                            v-model="menuFechaPago"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="computedFechaPago"
                                label="Fecha de Pago"
                                persistent-hint
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.fecha_pago"
                              no-title
                              @input="menuFechaPago = false"
                              :max="nowDate"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="5" sm="12">
                          <v-autocomplete
                            :items="estados"
                            item-value="id"
                            item-text="nombre"
                            label="Estado"
                            v-model="editedItem.estado_id"
                            :rules="[$rulesRequerido]"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="7" sm="12">
                          <v-textarea
                            clearable
                            clear-icon="mdi-close-circle"
                            v-model="editedItem.observaciones"
                            label="Observaciones"
                            auto-grow
                            rows="1"
                            row-height="15"
                            maxlength="255"
                            counter="255"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha ? $formatDate(item.fecha) : null }}
        </template>
        <template v-slot:[`item.monto`]="{ item }">
          {{
            item.monto ? "$" + Number(item.monto).toLocaleString("es-AR") : null
          }}
        </template>
        <template v-slot:[`item.fecha_vencimiento`]="{ item }">
          {{
            item.fecha_vencimiento ? $formatDate(item.fecha_vencimiento) : null
          }}
        </template>
        <template v-slot:[`item.fecha_pago`]="{ item }">
          {{ item.fecha_pago ? $formatDate(item.fecha_pago) : null }}
        </template>
        <template v-slot:[`item.activo`]="{ item }">
          {{ item.activo == 1 ? "Desarchivado" : "Archivado" }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="item.activo != 0"
            small
            class="mr-2"
            color="green"
            title="Editar"
            @click="$editItem(item.id)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="item.activo == 0"
            small
            color="blue"
            title="Desarchivar"
            @click="$enabledItem(item, 1)"
          >
            mdi-archive-arrow-up-outline
          </v-icon>
          <v-icon
            v-else
            small
            color="orange"
            title="Archivar"
            @click="$enabledItem(item, 0)"
          >
            mdi-archive-arrow-down
          </v-icon>
          <v-icon
            v-if="visible"
            small
            color="red"
            title="Eliminar"
            @click="$deleteItem(item.id, item.nombre)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-row>
      <v-col cols="12" md="12" sm="12" class="text-right">
        <v-btn color="success">
          <download-excel
            :fields="json_fields"
            :fetch="fetchData"
            :type="dataExportType"
            :name="
              'export-' + title + '-' + this.$getDate() + '.' + dataExportType
            "
          >
            Exportar Excel
          </download-excel>
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
function title() {
  return "Gastos";
}

export default {
  data: (vm) => ({
    options: {
      locale: "es-AR",
      prefix: "$",
      suffix: "",
      length: 11,
      precision: 2,
    },
    valid: true,
    menuFecha: false,
    menuFechaPago: false,
    menuFechaVencimiento: false,
    nowDate: new Date().toISOString().slice(0, 10),
    dessertMonto: "",
    dessertFecha: "",
    dessertFechaVencimiento: "",
    dessertFechaPago: "",
    dessertEstado: "",
    _method: "PUT",
    autoGrow: true,
    rows: 1,
    title: title(),
    route: "gastos",
    menu: false,
    modal: false,
    menu2: false,
    dialog: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    rules: [(v) => v.length <= 100 || "Max 100 caracteres"],
    //rulesRequired: [(v) => !!v || "Requerido"],
    search: "",
    users: false,
    headers: [
      { text: "ID", value: "id" },
      { text: "Fecha del gasto", value: "fecha" },
      { text: "Monto del gasto", value: "monto" },
      { text: "Fecha de Pago", value: "fecha_pago" },
      { text: "Archivo", value: "" },
      { text: "Estado", value: "estado" },
      { text: "Creado", value: "created_at" },
      { text: "Actualizado", value: "updated_at" },
      { text: "Acciones", value: "actions", sortable: false },
    ],

    desserts: [],
    editedIndex: -1,
    editedItem: {},
    estados: [],
    defaultItem: {},
    json_fields: {
      id: "id",
      /*numero: "numero",*/
      fecha: "fecha",
      monto: "monto",
      "Fecha Vencimiento": "fecha_vencimiento",
      "Fecha de Pago": "fecha_pago",
      estado: "estado",
      creado: "created_at",
      actualizado: "updated_at",
    },
    dataExportType: "xls",
  }),

  computed: {
    computedFecha() {
      return this.$formatDate(this.editedItem.fecha);
    },
    computedFechaPago() {
      return this.$formatDate(this.editedItem.fecha_pago);
    },
    computedFechaVencimiento() {
      return this.$formatDate(this.editedItem.fecha_vencimiento);
    },
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar " + this.title
        : "Editar " + this.title;
    },
    filteredDesserts() {
      let conditions = [];
      if (this.dessertMonto) {
        conditions.push(this.filterDessertMonto);
      }
      if (this.dessertFecha) {
        conditions.push(this.filterDessertFecha);
      }
      if (this.dessertEstado) {
        conditions.push(this.filterDessertEstado);
      }
      if (conditions.length > 0) {
        return this.desserts.filter((dessert) => {
          return conditions.every((condition) => {
            return condition(dessert);
          });
        });
      }

      return this.desserts;
    },
  },

  watch: {
    dialog(val) {
      val || this.$close();
    },
  },

  created() {
    this.$initialize();
    this.getEstados();
    this.isVisible();
  },

  methods: {
    async fetchData() {
      const response = this.filteredDesserts;
      return response;
    },
    isVisible() {
      //this.visible = this.$keycloak.hasResourceRole("administrador")
      this.visible = this.is("administrador") ? true : false;
    },
    getEstados() {
      this.$axiosApi
        .get("estados")
        .then((response) => {
          this.estados = response.data;
          let ids = [4, 5, 6];
          let options = [];
          if (ids) {
            options = this.estados.filter((o) => {
              return ids.find((element) => {
                return element === o.id;
              });
            });
          }
          this.estados = options;
          //console.log(options);
          /*
          if (id) {
            options = this.estados.filter((o) => {
              return o.documentacion_id === id;
            });
          }*/
        })
        .catch((response) => {
          this.snackbar = true;
          this.text =
            "Error al obtener estados. Contacte al administrador del sistema";
          this.color = "error";
        });
    },
    filterDessertMonto(item) {
      return item.monto.toLowerCase().includes(this.dessertMonto.toLowerCase());
    },
    filterDessertFecha(item) {
      return item.fecha.toLowerCase().includes(this.dessertFecha.toLowerCase());
    },
    filterDessertEstado(item) {
      return item.estado
        .toLowerCase()
        .includes(this.dessertEstado.toLowerCase());
    },
  },
  mounted() {
    console.log("Componente " + this.title + " creado");
  },
};
</script>
